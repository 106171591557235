var findAndCreateTimeout = function() {
  var timeoutRequestElement = document.querySelector('.timeout-request')
  if (!timeoutRequestElement) { return }

  var pollPath = timeoutRequestElement.dataset.path
  var useTurbo = timeoutRequestElement.dataset.useTurbo

  var requestAsync = function() { Rails.ajax({url: pollPath, type: 'GET'}) }
  var requestTurbo = function() { Turbo.visit(pollPath)  }
  var request = useTurbo ? requestTurbo : requestAsync

  var msDelay = Number(timeoutRequestElement.dataset.msDelay)
  setTimeout(request, msDelay)
}

document.addEventListener('turbo:load', findAndCreateTimeout)
